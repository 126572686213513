<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="10" lg="10"
        ><v-list two-line>
          <v-list outlined elevation="2" class="pa-0">
            <template>
              <v-toolbar min-width="100%" dark color="primary">
                <v-card-title>Examboard Board</v-card-title>
              </v-toolbar>

              <DataTableSSR
                apiEndPoint="/active_examboard/"
                :headers="headers"
                :instantLoad="true"
              >
                <template #[`item.created_at`]="{ item }">
                  {{ new Date(item.created_at).toLocaleString() }}
                </template>
                <template #[`item.actions`]="{ item }">
                  <v-btn color="primary" @click="viewExamboard(item)">
                    View
                  </v-btn>

                  <v-dialog v-model="item.dialog" width="70%">
                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        {{ examboard.title }}
                        <v-list-item-subtitle>
                          {{ new Date(examboard.created_at).toLocaleString() }}
                        </v-list-item-subtitle>
                      </v-card-title>
                      <v-card-text>{{ examboard.detail }}</v-card-text>
                      <v-card-text>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center"
                              >
                                <v-col>
                                  <v-hover v-slot="{ hover }">
                                    <v-card
                                      :elevation="hover ? 12 : 2"
                                      :class="{ 'on-hover': hover }"
                                    >
                                      <v-img :src="item.file" height="205px">
                                        <v-card-title
                                          class="text-h6 white--text"
                                        >
                                          <v-row
                                            class="fill-height flex-column"
                                            justify="space-between"
                                          >
                                          </v-row>
                                        </v-card-title>
                                      </v-img>
                                    </v-card>
                                  </v-hover>
                                </v-col>
                              </v-row>
                            </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="closeDialog(item)">Close</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
              </DataTableSSR>

              <v-divider
                v-if="index < items.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list> </v-list
      ></v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataTableSSR from "../../components/global/DataTableSSR.vue";
export default {
  components: { DataTableSSR },
  data: () => ({
    items: [],
    pagination: {},
    dialog: false,
    examboard: {},
    headers: [
      {
        text: "Created At",
        value: "created_at",
      },
      {
        text: "Title",
        value: "title",
      },
      {
        text: "Actions",
        value: "actions",
        align: "right",
      },
    ],
    
  }),
  created() {
    this.loadExamboard();
  },

  methods: {
    async viewExamboard(item) {
      await this.$api.get(`/active_examboard/${item.id}/`).then((response) => {
        this.examboard = response.data;
        item.dialog = true;
      });
    },
    closeDialog(item) {
    this.$set(item, 'dialog', false); 
    this.examboard = {}; 
  },
    loadExamboard() {
      this.$api
        .get("/active_examboard/")
        .then((response) => {
          const { results, ...pagination } = response.data;
          this.items = results;
          this.pagination = pagination;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>

